/*
    Any functions placed here will be appended to '_utils'
*/

import { ThemeLink, THEME_LINKS, THEME_LINK_TYPE } from "_types";
import { config } from '_core';
import { v4 } from 'uuid'

/** @module ThemeUtils **/

export const getRouterLink = function (url: string) {
  return url.startsWith(config.baseUrl) ? url.slice(config.baseUrl.length) : url;
};

export const generateID = function () {
  return v4();
};

export const transform = function (element: HTMLElement, x = 0, y = 0, scale = 1) {
  if (element && element.style)
    element.style.transform = `translate3d(${x}px, ${y}px, 0px)${scale !== 1 ? ` scale(${scale})` : ""
      }`;
}

export const generatePageLink = function (link: ThemeLink) {
  if (link.type === THEME_LINK_TYPE.PAGE_LINK)
    return getRouterLink(link.page_link);
  else if (link.type === THEME_LINK_TYPE.THEME_LINK)
    if (link.theme_link === THEME_LINKS.HOME_PAGE) return "/";
  if (link.theme_link === THEME_LINKS.ALL_ARTICLES) return "/posts/";
  console.warn(`no link type for ${link.type}}`);
  return "";
}

export const arrayContainsObject = (array: object[], object: object) => {
  return array.some((item) =>
    Object.keys(item).every((key) => item[key] === object[key])
  );
};

// A cross-browser safe way to convert wordpress post/page date to usable output date.
export const wordpressDateToString = (date: string): string => {
  const output = new Date(date.replace(/-/g, "/"));
  return output ? output.toLocaleDateString("en-UK") : '';
}